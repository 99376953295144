import * as React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { ContactForm } from '../components/forms/contact'
import { Layout } from "../components/global/layout"
import { Masthead } from "../components/layout/masthead"

const ContactPage = ({ data, pageContext }) => {
    if (!data) 
      return null
  
    const document = data.prismicContact

    return (
        <Layout pageTitle={document.data.title.text} 
                metaTitle={document.data.meta_title.text} 
                metaDescription={document.data.meta_description.text || document.data.summary.text}
                shareImage={document.data.share_image.url}>
            <Masthead subtitle={document.data.subtitle.text} title={document.data.title.text} summary={document.data.summary.text} />
            <ContactForm title={document.data.contactus_title.text} summary={document.data.contactus_summary.text} image={document.data.contactus_image.url} buttonText={document.data.contactus_buttontext.text} />
        </Layout>
    )
}

export const query = graphql`
  query ContactUsQuery {
    prismicContact {
      _previewable
      data {
        title {
          text
        }
        summary {
          text
        }
        subtitle {
          text
        }
        contactus_title {
          text
        }
        contactus_summary {
          text
        }
        contactus_image {
          url
        }
        contactus_buttontext {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
      }
    }
  }  
`

export default withPrismicPreview(ContactPage)

