import React, { useState } from "react"
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import {useMutation} from "react-query";
import { pages, siteMetadata } from '../../site-config'
import {contact} from "../../api/associates";



export const ContactForm = ({ title, summary, image, buttonText }) => {     
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false
      });

    const contactMutation = useMutation({
        mutationKey: ['contact-form'],
        mutationFn: (data) => {
            return contact(data);
        },
        onSuccess: async (response) => {
            setState({
                isSubmitted: true,
            });
        },
        onError: (error) => {
            setState({
                isError: true,
            });
        }
    });

    const onSubmit = (data) => {
        contactMutation.mutate(data);
    }

    return (
        <div className="c-contact">
            <div className="container">
                <div className="row">
                    <div className="c-contact__media-container">
                        <img src={image !== null ? image : "/images/test/contact.png"} alt="" className="c-contact__media" loading="lazy" />
                    </div>
                    <div className="c-contact__form">
                        {title ? 
                            <h2 className="c-contact__title">{title}</h2> : null}
                        {summary ? 
                            <p className="c-contact__summary">{summary}</p> : null}
                        {!state.isSubmitted ? 
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <legend className="c-form__legend">Contact</legend>
                                    <div className="c-contact__form-fields">
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="name" className="c-form__label">Name</label>
                                                <input id="name" name="name" placeholder="Full Name" className={errors.name ? "c-form__textbox error" : "c-form__textbox"} {...register("name", { required: true })} />
                                                {errors.name && <div className="c-form__validation c-form__validation--error"><p>Please enter your name</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textarea-field">
                                                <label htmlFor="body" className="c-form__label">Message</label>
                                                <textarea rows="3" id="body" name="body" placeholder="Message" className={errors.body ? "c-form__textarea error" : "c-form__textarea"} {...register("body", { required: true })}></textarea>
                                                {errors.body && <div className="c-form__validation c-form__validation--error"><p>Please enter your message</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <div className="c-form__textbox-field">
                                                <label htmlFor="email" className="c-form__label">Email</label>
                                                <input id="email" name="email" placeholder="Email" className={errors.contactemail ? "c-form__textbox error" : "c-form__textbox"} {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                                {errors.email && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                            </div>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <p className="c-contact__form-consent">By clicking the 'Send Message' button you're confirming that you agree with our following <Link to={pages.privacyPolicy}>Privacy Policy</Link>.</p>
                                        </div>
                                        {buttonText ? 
                                        <div className="c-contact__form-field">
                                            <input type="submit" value={buttonText} className="c-contact__submit c-btn c-btn--large c-btn--primary" />
                                        </div> : null}
                                    </div>
                                </fieldset>
                                {state.isError ? <p className="c-contact__summary">Unfortunately, your submission could not be sent. Please try again later.</p> : null}    
                            </form>
                            : <p className="c-contact__summary">Thank you for contacting us. We will be in touch shortly.</p>}
                        <h3 className="c-contact__subtitle">Get in touch</h3>
                        <p className="c-contact__summary">
                            <a target="_blank" rel="noreferrer" href={`mailto:${siteMetadata.email.contact}`}>{siteMetadata.email.contact}</a>
                            <a href="tel:+443330020240">+44 333 002 0240</a>
                        </p>
                        <h3 className="c-contact__subtitle">Looking for job or consulting?</h3>
                        <p className="c-contact__summary">
                            <a target="_blank" rel="noreferrer" href={`mailto:${siteMetadata.email.consulting}`}>{siteMetadata.email.consulting}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;