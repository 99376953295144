/**
 * Send a contact form to the API
 *
 * @module api/associates
 * @param data
 * @returns {Promise<Response>}
 */
async function contact(data = {}) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/contact/`, {
            method: 'POST',
            body: formData,
    });

    if (response.status >= 200 && response.status < 300) {
        const { form_errors: formErrors } = await response.json();

        if (!formErrors) {
            return response;
        } else {
            const error = new Error(response.statusText);
            error.response = formErrors;
            throw error;
        }
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Subscribe to the newsletter
 *
 * @param data
 * @returns {Promise<Response>}
 */
async function subscribe(data = {}) {
    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/newsletter/subscribe/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
    });

    if (response.status >= 200 && response.status < 300) {
        const { form_errors: formErrors } = await response.json();

        if (!formErrors) {
            return response;
        } else {
            const error = new Error(response.statusText);
            error.response = formErrors;
            throw error;
        }
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Register login details
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function registerLoginDetails(data = {}) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/auth/register/login_details/`, {
            method: 'POST',
            body: formData,
    });

    if (response.status >= 200 && response.status < 300) {
        const { form_errors: formErrors, ...data } = await response.json();

        if (!formErrors) {
            return data;
        } else {
            const error = new Error(response.statusText);
            error.response = formErrors
            throw error;
        }
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


/**
 * Preregister
 * @param data
 * @returns {Promise<Omit<any, "form_errors">>}
 */
async function preregister(data = {}) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        if(['consulting_work', 'goals'].includes(key)) {
            formData.append(key, JSON.stringify(data[key]));
        } else {
            formData.append(key, data[key]);
        }
    });

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/preregister/register/`, {
            method: 'POST',
            body: formData,
    });

    if (response.status >= 200 && response.status < 300) {
        const { form_errors: formErrors, ...data } = await response.json();

        if (!formErrors) {
            return data;
        } else {
            const error = new Error(response.statusText);
            error.response = formErrors
            throw error;
        }
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}


export {
    contact, subscribe,
    registerLoginDetails, preregister
};